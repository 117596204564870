import * as React from "react"
import Markdown from "react-markdown"
import "node-self"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import Logo from "../images/white_nextierplastics_logo.svg"
import { Link } from "gatsby"

// import "@splidejs/splide/dist/css/themes/splide-default.min.css"

const ImageBlockNav = ({ title, nav, image }) => {
  const slider = React.createRef()
  return (
    <section className="home-split-section">
      <div className="full-width-grid">
        <div className="full-width-grid__solid-background"></div>
        <div className="full-width-grid__solid-content">
          <h2>{title}</h2>
          <div id="made-of-carousel" className="inner-content">
            {nav.map((item, i) => (
              <Link
                to={item.Link}
                className="secondary-btn_alt made-of-button"
                data-image="/assets/images/pexels-kelly-lacy-3127161.jpg"
                data-value={i}
                onMouseOver={e => {
                  slider.current.splide.go(i + 1)
                }}
                onMouseOut={e => {
                  slider.current.splide.go(0)
                }}
              >
                {item.Title}
              </Link>
            ))}
          </div>
        </div>

        <Splide
          ref={slider}
          id="made-of-slider"
          className="full-width-grid__image-background splide"
          options={{
            arrows: false,
            cover: true,
            pagination: false,
          }}
        >
          <SplideSlide>
            <div className="slide-content">
              <img id="made-of-carousel__logo" src={Logo} />
            </div>
            <div
              className="slide-background"
              style={{ backgroundImage: `url(${image.url})` }}
            />
          </SplideSlide>

          {nav.map((item, i) => (
            <SplideSlide className="">
              <div className="slide-content-bottom">
                <h3 className="slide-title">{item.Title}</h3>
                <div className="slide-text">
                  <Markdown children={item.Text} />
                </div>
              </div>
              {item.Image && (
                <div
                  className="slide-background"
                  style={{ backgroundImage: `url(${item.Image.url})` }}
                />
              )}
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </section>
  )
}

export default ImageBlockNav
