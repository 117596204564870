import * as React from "react"

const FullWidthImage = ({ image }) => {
  return (
    <section
      className="about-fullwidth"
      style={{ backgroundImage: `url(${image.url})` }}
    />
  )
}

export default FullWidthImage
