import * as React from "react"
import ContentBlock from "./ContentBlock"
import ImageBlockNav from "./ImageBlockNav"
import IndustriesCard from "./IndustriesCard"
import SplitContentWithNav from "./SplitContentWithNav"
import Conditions from "./Conditions"
import Slider from "./Slider"
import AboutHeader from "./AboutHeader"
import FullWidthImage from "./FullWidthImage"
import BlueXContent from "./BlueXContent"
import PartnerCard from "./PartnerCard"
import SplitImage from "./SplitImage"

const ContentRenderer = ({ node, zone }) => {
  const content = node?.Content ?? zone

  const render = []
  for (let i = 0; i < content.length; i++) {
    const element = content[i]

    switch (element.strapi_component) {
      case "shared.split-image":
        render.push(<SplitImage images={element.Images} />)
        break

      case "sections.partner-card":
        render.push(
          <PartnerCard
            title={element.Title}
            text={element.Text}
            partners={element.partners}
            disableButton={element.DisableButton}
            line={element.line}
          />
        )
        break
      case "sections.blue-x-content":
        render.push(
          <BlueXContent
            title={element.textContent.Title}
            text={element.textContent.Text}
          />
        )
        break

      case "shared.full-width-image":
        render.push(<FullWidthImage image={element.Image} />)
        break

      case "sections.about-hero":
        render.push(
          <AboutHeader
            title={element.Title}
            text={element.Text}
            image={element.Image}
          />
        )
        break

      case "sections.hero":
        render.push(<Slider slides={element.Slide} />)
        break

      case "sections.content-block":
        render.push(
          <ContentBlock
            title={element.Title}
            text={element.Text}
            line={element.line}
          />
        )
        break

      case "sections.industries-card":
        render.push(
          <IndustriesCard
            title={element.Title}
            industries={element.industries}
            image={element.Image?.url}
          />
        )
        break

      case "sections.split-content-with-nav":
        render.push(
          <SplitContentWithNav
            title={element.Title}
            text={element.Text}
            nav={element.NavItem}
            image={element.Image}
          />
        )
        break

      case "sections.image-block-nav":
        render.push(
          <ImageBlockNav
            title={element.Title}
            text={element.Text}
            nav={element.Links}
            image={element.Image}
          />
        )
        break

      case "sections.conditions":
        render.push(
          <Conditions title={element.Title} list={element.condition} />
        )
        break

      default:
        break
    }
  }

  return render
  // return <h1>Hello</h1>
}

export default ContentRenderer
