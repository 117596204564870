import { Link } from "gatsby"
import * as React from "react"

const IndustriesCard = ({ title, industries, image }) => {
  return (
    <>
      <section className="home-centered-block-image">
        <div className="blue-line-container">
          <div className="container">
            <div
              className="centered-block-image__background"
              style={{
                backgroundImage: `url(${image})`,
              }}
            >
              <Link to="/products" className="large-btn">
                All Plastics
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="home-industries">
        <div className="blue-line-container">
          <div className="container">
            <div className="home-industries__title">
              <h2>{title}</h2>
            </div>
            <div className="home-industries__content-grid">
              {industries.map(industry => (
                <Link
                  to={`/industries/${industry.slug}`}
                  key={industry.id}
                  className="secondary-btn icon-btn"
                >
                  <div>
                    <img
                      className="icon"
                      src={industry.Icon?.url ?? ""}
                      alt={industry.Icon?.alternativeText}
                    />
                    {industry.Title}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default IndustriesCard
