import { Link } from "gatsby"
import * as React from "react"
import Markdown from "react-markdown"

const PartnerCard = ({
  title,
  text,
  partners,
  disableButton = false,
  line = false,
}) => {
  return (
    <>
      {line ? (
        <section className="about-block3 product-details-block">
          <div className="blue-line-container about-block3__inner">
            <div className="content-container">
              <h2 className="about-block3__title">{title}</h2>
              <div className="about-block3__text">
                <Markdown children={text} />
              </div>
              {!disableButton && (
                <Link to="/partners" className="primary-btn btn-arrow">
                  <span>Our Partners</span>
                </Link>
              )}
              <div className="green-line"></div>
            </div>
            <div className="about-block3__logo-container">
              {partners &&
                partners.map(partner => (
                  <div className="about-block3__logo">
                    <img
                      src={partner.Logo.url}
                      alt={partner.Logo.alternativeText}
                      style={{ maxHeight: "100px", width: "auto" }}
                    />
                    <h6>{partner.Name}</h6>
                  </div>
                ))}
            </div>
          </div>
        </section>
      ) : (
        <section className="about-block3">
          <div className="container about-block3__inner">
            <div className="content-container">
              <h2 className="about-block3__title">{title}</h2>
              <div className="about-block3__text">
                <Markdown children={text} />
              </div>
              {!disableButton && (
                <Link to="/partners" className="primary-btn btn-arrow">
                  <span>Our Partners</span>
                </Link>
              )}
              <div className="green-line"></div>
            </div>
            <div className="about-block3__logo-container">
              {partners &&
                partners.map(partner => (
                  <div className="about-block3__logo">
                    <img
                      src={partner.Logo.url}
                      alt={partner.Logo.alternativeText}
                      style={{ maxHeight: "100px", width: "auto" }}
                    />
                    <h6>{partner.Name}</h6>
                  </div>
                ))}
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default PartnerCard
