import * as React from "react"
import "node-self"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import Markdown from "react-markdown"

import CarouselArrow from "../images/nextier-carousel-arrow.svg"
import { Link } from "gatsby"

const Slider = ({ slides }) => {
  const primaryRef = React.createRef()
  const secondaryRef = React.createRef()

  React.useEffect(() => {
    primaryRef.current.sync(secondaryRef.current.splide)
  }, [])

  return (
    <>
      <Splide
        id="hero"
        className="splide"
        ref={primaryRef}
        options={{
          arrows: true,
          pagination: false,
        }}
        renderControls={() => (
          <div className="splide__arrows">
            <button className="splide__arrow nextier-hero-arrow splide__arrow--prev">
              <img className="transform rotate-0" src={CarouselArrow} />
            </button>
            <button className="splide__arrow nextier-hero-arrow splide__arrow--next">
              <img className="transform rotate-180" src={CarouselArrow} />
            </button>
          </div>
        )}
      >
        {slides.map(slide => (
          <SplideSlide>
            <div className="container slide-content">
              <div className="slide-content__inner">
                <h1 className="slide-content__title">{slide.Title}</h1>
                <div className="slide-content__text">
                  <Markdown children={slide.Text} />
                </div>
                {slide.buttontext !== "" && (
                  <Link
                    to={slide.buttonlink}
                    className="primary-btn btn-arrow slide-content__link"
                  >
                    <span>{slide.buttontext}</span>
                  </Link>
                )}
              </div>
            </div>
            <div
              className="slide-background"
              style={{ backgroundImage: `url(${slide?.Image?.url})` }}
            />
          </SplideSlide>
        ))}
      </Splide>
      <div className="secondary-slider">
        <div className="blue-line-container_flush">
          <Splide
            ref={secondaryRef}
            options={{
              width: 1200,
              height: 110,
              gap: 20,
              cover: true,
              pagination: false,
              arrows: false,
              isNavigation: true,
              perPage: 4,
              breakpoints: {
                1200: {
                  perPage: 3,
                  pagination: true,
                  arrows: true,
                },
                996: {
                  perPage: 2,
                  pagination: true,
                  arrows: true,
                },
              },
            }}
            id="nav-slider"
            className="splide container"
          >
            {slides.map(slide => (
              <SplideSlide>
                <h6>{slide.Title}</h6>
                <div
                  className="nav-slide"
                  style={{ backgroundImage: `url(${slide?.Image?.url})` }}
                />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </>
  )
}

export default Slider
