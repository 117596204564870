import { Link } from "gatsby"
import * as React from "react"

const SplitContentWithNav = ({ title, text, nav = [], image }) => {
  return (
    <section className="home-technical-services">
      <div className="blue-line-container">
        <div className="container page-spacing-md">
          <div className="home-technical-services__left-container">
            <div className="inner-content">
              <h2 className="home-technical-services__title">{title}</h2>
              <div className="content-box">
                <div className="green-line"></div>
                <div className="home-technical-services__text">
                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                  <Link to="/services" className="primary-btn btn-arrow">
                    <span>View Services</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="home-technical-services__image-container">
              <img src={image.url} />
            </div>
          </div>
          <div className="home-technical-services__right-container">
            {nav.map(item => (
              <div key={item.id} className="home-technical-services__item">
                <h6>{item.Text}</h6>
              </div>
            ))}

            {/* <div className="home-technical-services__item">
              <h6>Testing Services</h6>
            </div>
            <div className="home-technical-services__item">
              <h6>Customization</h6>
            </div>
            <div className="home-technical-services__item">
              <h6>Problem Solving</h6>
            </div>
            <div className="home-technical-services__item">
              <h6>Technical Support Team</h6>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SplitContentWithNav
