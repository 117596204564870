import * as React from "react"
import Markdown from "react-markdown"

const ContentBlock = ({ title, text, line }) => {
  return (
    <>
      {line ? (
        <section className="product-details-block">
          <div className="blue-line-container">
            <div className="container product-details-block__inner">
              <div className="content-container">
                <h2 className="product-details-block__title">{title}</h2>
                <div className="content-box">
                  <div className="green-line"></div>
                  <div className="product-details-block__content">
                    <Markdown children={text} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="product-details-noline">
          <div className="container product-details-noline__inner">
            <div className="content-container">
              <h2 className="product-details-noline__title">{title}</h2>
              <div className="content-box">
                <div className="green-line"></div>
                <div className="product-details-noline__text">
                  <Markdown children={text} />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ContentBlock
