import * as React from "react"
import Markdown from "react-markdown"

import { sliceHalf } from "../helpers/functions"

const Conditions = ({ title, list }) => {
  const [group1, group2] = sliceHalf(list)


  return (
    <section className="product-reverse-block">
      <div className="container product-reverse-block__inner">
        <h2 className="product-reverse-block__title">{title}</h2>
        <div className="content-container">
          <div className="product-reverse-block__col1">
            {group1.map(condition => (
              <div className="product-reverse-block__item">
                <h5 className="product-reverse-block__item-title">
                  {condition.Title}
                </h5>
                <Markdown children={condition.Text} />
              </div>
            ))}
          </div>
          <div className="product-reverse-block__col2">
            {group2.map(condition => (
              <div className="product-reverse-block__item">
                <h5 className="product-reverse-block__item-title">
                  {condition.Title}
                </h5>
                <Markdown children={condition.Text} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Conditions
