import * as React from "react"

import Markdown from "react-markdown"
import GreenArrow from "../images/green-arrow.svg"

const AboutHeader = ({ title, text, image }) => {
  return (
    <section className="nextier-about-hero">
      <div className="blue-line-container">
        <div className="container nextier-about-hero__inner">
          <h1 className="nextier-about-hero__title">{title}</h1>
          <div className="nextier-about-hero__subtitle">
            <Markdown children={text} />
          </div>
          <img src={GreenArrow} className="nextier-about-hero__arrow" />
        </div>
      </div>
      <img
        src={image?.url}
        alt={image?.alternativeText}
        className="nextier-about-hero__image"
      />
    </section>
  )
}

export default AboutHeader
