import * as React from "react"

const SplitImage = ({ images }) => {
  console.log("Images", images)
  return (
    <section className="split-image">
      <div className="blue-line-container"></div>
      {images.map(image => (
        <div className="split-image__wrapper">
          <img
            alt={image.alternativeText}
            className="split-image__image"
            src={image.url}
          />
        </div>
      ))}

      {/* <div className="split-image__wrapper">
        <img
          className="split-image__image"
          src="https://picsum.photos/200/300"
        />
      </div> */}
    </section>
  )
}

export default SplitImage
