export const sliceIntoChunks = (arr, chunkSize) => {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export const sliceHalf = arr => {
  const res = []
  const half = Math.ceil(arr.length / 2)

  return [arr.slice(0, half), arr.slice(-half)]
}

export const download = (url, name) => {
  fetch(url, {
    method: "GET",
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = name
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() //afterwards we remove the element again
    })
}
