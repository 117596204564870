import * as React from "react"
import Markdown from "react-markdown"

import LargeX from "../images/large-x.svg"

const BlueXContent = ({ title, text, image }) => {
  return (
    <section className="about-block2">
      <div className="container about-block2__inner">
        <div className="content-container">
          <h2 className="about-block2__title">{title}</h2>
          <div className="content-block">
            <div className="green-line"></div>
            <div className="about-block2__text">
              <Markdown children={text} />
              <a
                href="https://nextiersourcing.com"
                target="_blank"
                rel="nofollow noopener"
                className="primary-btn_alt btn-arrow"
              >
                <span>View Site</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <img src={LargeX} className="about-block2__image" />
    </section>
  )
}

export default BlueXContent
